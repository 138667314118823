import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'; 
import styled from 'styled-components';
import '../fonts/fonts.css';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Contenedor de la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el contenedor ocupe al menos el 100% de la altura de la pantalla */
`;

// Contenedor del contenido principal
const Content = styled.main`
  flex: 1; /* Esto permite que el contenido crezca y ocupe el espacio disponible */
`;

// Imagen de fondo con filtro de color
const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* La altura de la imagen de fondo ocupará toda la ventana */
  background-image: url('URL_DE_LA_IMAGEN'); /* Reemplaza con la URL de la imagen */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  text-align: center;
margin-top: 5rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

// Texto centrado sobre la imagen de fondo
const TextOverlay = styled.div`
  position: relative;
  z-index: 2;
  color: #050556;
  font-size: 1.5rem;
  font-family: 'Font';
  padding: 0 20px;

  @media (min-width: 768px) {
    font-size: 2rem; /* Aumenta el tamaño del texto en pantallas más grandes */
  }

  @media (min-width: 1025px) {
    font-size: 2.5rem; /* Aumenta aún más en pantallas grandes */
  }
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function Nosotros() {
  return (
    <PageContainer>
      <Navbar />
      <Content style={{ marginTop: '1rem' }}>
        <h1 style={{
          display: 'flex',
          justifyContent: 'center',
          fontFamily: 'Font',
          color: '#575756',
          fontSize: '20px',
          marginBottom: '1rem'
        }}>
          NOSOTROS
        </h1>
        <StyledSeparator />
        {/* Imagen de fondo con filtro y texto centrado */}
        <Background>
          <TextOverlay>
            Un nissan skyline R34 a 240kmh rumbo a la nueva ruta del bakalao.
            <br />
            El piloto de este viaje es Selecta, que conduce sus fiestas a los lugares que le marcaron como productor. Los graves del maletero de ALERON retumban entre lo electronico y lo urbano. De DJ Marta a Natos y Waor.
            <br />
            ALERON no es solo una fiesta que reune a las caras mas reconocidas del genero urbano en Madrid. Es un espiritu, el de Selecta, en el que convergen el hip-hop, el techno y el pop a 150 BPM´s para crear un nuevo género: el NEOBAKLA.
          </TextOverlay>
        </Background>
      </Content>
      <Footer /> 
    </PageContainer>
  );
}

export default Nosotros;
