import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
//import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 2rem;
  color: #050556;
  font-family: "Font";
`;

const SubHeading = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #575756;
  font-family: "Font"
`;

const HomeLink = styled.div`
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #050556;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: inline-block;
    font-family: "Font"

  &:hover {
    background-color: #333;
  }
`;

/* const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`; */

const NotFound = () => {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Heading>¿Te has perdido?</Heading>
        <SubHeading>Parece que la página que buscas no existe.</SubHeading>
        <HomeLink>
          <a href="/" style={{ color: "white", textDecoration: "none", fontFamily: "Font" }}>Volver al Inicio</a>
        </HomeLink>
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default NotFound;
