import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const CartItemDetails = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100px;
    height: auto;
    margin-right: 20px;
  }
`;

const CartItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CartItemActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Total = styled.h2`
  margin-top: 20px;
  text-align: right;
  color: #050556;
`;

const CheckoutButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #050556;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #001a5e;
  }
`;

function Carrito() {
  const [carrito, setCarrito] = useState(null);
  const [usuario, setUsuario] = useState('usuario-ejemplo'); // Identificador de usuario

  useEffect(() => {
    const fetchCarrito = async () => {
      try {
        const response = await fetch(`https://backaleron.aleronclub.com/api/carrito?usuario=${usuario}`, {
          headers: {
            'Authorization': 'APIAleron2025'
          }
        });
        if (!response.ok) {
          throw new Error('No se pudo cargar el carrito');
        }
        const data = await response.json();
        setCarrito(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCarrito();
  }, [usuario]);

  const calcularTotal = () => {
    return carrito?.items.reduce((total, item) => total + item.producto.precio * item.cantidad, 0) || 0;
  };

  if (!carrito) {
    return <p>Cargando...</p>;
  }

  return (
    <PageContainer>
      <Navbar />
      <Content>
        <h1 style={{color: "#050556", marginLeft: "10rem"}}>
          CARRITO DE COMPRAS
        </h1>
        {carrito.items.map((item) => (
          <CartItem key={item.id}>
            <CartItemDetails>
              <img src={item.producto.imagen} alt={item.producto.nombre} />
              <CartItemInfo>
                <h3>{item.producto.nombre}</h3>
                {item.talla && <p>Talla: {item.talla}</p>}
              </CartItemInfo>
            </CartItemDetails>
            <CartItemActions>
              <p>Cantidad: {item.cantidad}</p>
              <p>Precio: ${item.producto.precio * item.cantidad}</p>
            </CartItemActions>
          </CartItem>
        ))}
        <Total>Total: ${calcularTotal()}</Total>
        <CheckoutButton>Pagar</CheckoutButton>
      </Content>
      <Footer /> 
    </PageContainer>
  );
}

export default Carrito;
