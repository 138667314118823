import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Estilos para la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Font";
  font-size: 20px;
  color: #575756;
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Font";
  font-size: 18px;
  color: #050556;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-family: "Font";
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function AvisoLegal() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Title>AVISO LEGAL Y POLÍTICA DE PROTECCIÓN DE DATOS</Title>
        <StyledSeparator />

        <Section>
          <Subtitle>1. Identificación del titular de la web</Subtitle>
          <Paragraph>
            En cumplimiento de lo dispuesto por la Ley 34/2002 de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), se informa a los usuarios del sitio web www.aleronclub.es (en adelante, “la Web”) de los siguientes datos de identificación del titular:
            <br />
            Titular: Selecta Creativos S.L.
            <br />
            CIF: B44783926
            <br />
            Domicilio Social: Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España.
            <br />
            Correo electrónico de contacto: HOLA@ALERONCLUB.COM
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Condiciones de uso</Subtitle>
          <Paragraph>
            El acceso y uso de la presente Web implica la aceptación de las condiciones de uso establecidas en este Aviso Legal. El titular se reserva el derecho a modificar en cualquier momento y sin previo aviso las presentes condiciones, por lo que se recomienda a los usuarios revisarlas de forma periódica.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Protección de datos personales</Subtitle>

          <Section>
            <Subtitle>3.1. Responsable del tratamiento</Subtitle>
            <Paragraph>
              El responsable del tratamiento de los datos personales facilitados a través de la Web es:
              <br />
              Identidad: Selecta Creativos S.L.
              <br />
              CIF: B44783926
              <br />
              Domicilio: Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España.
              <br />
              Correo electrónico de contacto: HOLA@ALERONCLUB.COM
            </Paragraph>
          </Section>

          <Section>
            <Subtitle>3.2. Finalidad del tratamiento</Subtitle>
            <Paragraph>
              Los datos personales recabados a través de los formularios de la Web serán tratados con las siguientes finalidades:
              <ul>
                <li>Gestionar la venta de entradas y los servicios asociados al club.</li>
                <li>Responder a las consultas o solicitudes de información realizadas por los usuarios.</li>
                <li>Enviar comunicaciones comerciales sobre productos, servicios o eventos que puedan ser de interés para el usuario, siempre que haya otorgado su consentimiento expreso.</li>
              </ul>
            </Paragraph>
          </Section>

          <Section>
            <Subtitle>3.3. Base jurídica para el tratamiento de datos</Subtitle>
            <Paragraph>
              El tratamiento de los datos personales se realizará bajo las siguientes bases jurídicas:
              <ul>
                <li>Ejecución de un contrato: Cuando sea necesario para la prestación de los servicios solicitados (venta de entradas).</li>
                <li>Consentimiento: Cuando se trate del envío de comunicaciones comerciales.</li>
                <li>Cumplimiento de una obligación legal: En aquellos casos en que estemos obligados por la normativa aplicable.</li>
              </ul>
            </Paragraph>
          </Section>

          <Section>
            <Subtitle>3.4. Conservación de los datos</Subtitle>
            <Paragraph>
              Los datos personales proporcionados se conservarán mientras sea necesario para la finalidad para la que fueron recabados o hasta que el interesado solicite su supresión, ejerza su derecho de oposición o retire su consentimiento.
            </Paragraph>
          </Section>

          <Section>
            <Subtitle>3.5. Cesión de datos a terceros</Subtitle>
            <Paragraph>
              No se cederán datos a terceros, salvo obligación legal o para la correcta prestación del servicio (por ejemplo, a plataformas de pago o entidades bancarias para procesar la compra de entradas).
            </Paragraph>
          </Section>

          <Section>
            <Subtitle>3.6. Derechos del usuario</Subtitle>
            <Paragraph>
              El usuario tiene derecho a acceder a sus datos personales, rectificar los datos inexactos, solicitar la supresión de sus datos, oponerse al tratamiento, solicitar la limitación del tratamiento, y solicitar la portabilidad de sus datos.
              Para ejercer estos derechos, el usuario puede enviar una solicitud por escrito a: 
              <br />
              Correo electrónico: HOLA@ALERONCLUB.COM 
              <br />
              Dirección postal: Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España.
            </Paragraph>
          </Section>
        </Section>

        <Section>
          <Subtitle>4. Propiedad intelectual e industrial</Subtitle>
          <Paragraph>
            Todos los contenidos de la Web están protegidos por derechos de propiedad intelectual e industrial de los cuales Selecta Creativos S.L. es titular o legítimo licenciatario. Queda prohibida su reproducción sin autorización expresa y por escrito.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>5. Exoneración de responsabilidad</Subtitle>
          <Paragraph>
            Selecta Creativos S.L. no será responsable de los daños y perjuicios que puedan derivarse de interferencias, omisiones, interrupciones, virus informáticos u otros problemas que puedan afectar al sistema electrónico de la Web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>6. Ley aplicable y jurisdicción</Subtitle>
          <Paragraph>
            El presente Aviso Legal se rige por la normativa española. Cualquier controversia será sometida a los juzgados y tribunales de Ávila.
          </Paragraph>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default AvisoLegal;
