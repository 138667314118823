import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Estilos para la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Font";
  font-size: 20px;
  color: #575756;
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Font";
  font-size: 18px;
  color: #050556;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-family: "Font";
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const CookieTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;

function PoliticaCookies() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Title>POLÍTICA DE COOKIES</Title>
        <StyledSeparator />

        <Section>
          <Paragraph>
            En Selecta Creativos S.L. (en adelante, "la Empresa"), utilizamos cookies con el fin de mejorar la experiencia de navegación de los usuarios en nuestro sitio web www.aleronclub.es (en adelante, "la Web"). A continuación, te explicamos qué son las cookies, qué tipos utilizamos y cómo puedes gestionarlas de acuerdo con la normativa vigente.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>1. ¿Qué son las cookies?</Subtitle>
          <Paragraph>
            Las cookies son pequeños archivos de texto que se almacenan en el dispositivo del usuario cuando visita un sitio web. Su finalidad es recordar información sobre la visita, lo que facilita el uso y mejora la experiencia de navegación en futuras visitas. Las cookies pueden ser instaladas por el propio sitio web que estás visitando (cookies propias) o por otros servicios (cookies de terceros) como herramientas de análisis o redes sociales.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Tipos de cookies que utilizamos</Subtitle>
          <Paragraph>
            En la Web utilizamos los siguientes tipos de cookies:
            <ul>
              <li><strong>Cookies propias:</strong> Son aquellas que enviamos a tu dispositivo desde nuestros propios servidores o dominios.</li>
              <li><strong>Cookies de terceros:</strong> Son aquellas enviadas desde un servidor o dominio que no es gestionado directamente por nosotros.</li>
              <li><strong>Cookies de sesión:</strong> Cookies temporales que se eliminan cuando cierras el navegador.</li>
              <li><strong>Cookies persistentes:</strong> Permanecen en tu dispositivo durante un tiempo definido.</li>
              <li><strong>Cookies técnicas:</strong> Esenciales para el correcto funcionamiento de la Web.</li>
              <li><strong>Cookies de personalización:</strong> Permiten recordar tus preferencias.</li>
              <li><strong>Cookies de análisis:</strong> Recopilan información de forma anónima para mejorar nuestros servicios (Ejemplo: Google Analytics).</li>
              <li><strong>Cookies publicitarias:</strong> Gestionan los espacios publicitarios en la Web y muestran anuncios relevantes.</li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Cookies utilizadas en la Web</Subtitle>
          <CookieTable>
            <thead>
              <tr>
                <th>Nombre de la Cookie</th>
                <th>Tipo</th>
                <th>Propósito</th>
                <th>Duración</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>Análisis (Google Analytics)</td>
                <td>Se usa para distinguir a los usuarios.</td>
                <td>2 años</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>Análisis (Google Analytics)</td>
                <td>Se usa para distinguir a los usuarios.</td>
                <td>24 horas</td>
              </tr>
              <tr>
                <td>PHPSESSID</td>
                <td>Técnica (propia)</td>
                <td>Cookie de sesión para mantener las variables de usuario.</td>
                <td>Sesión</td>
              </tr>
              <tr>
                <td>cookie_consent</td>
                <td>Técnica (propia)</td>
                <td>Almacena el consentimiento de cookies del usuario.</td>
                <td>1 año</td>
              </tr>
            </tbody>
          </CookieTable>
        </Section>

        <Section>
          <Subtitle>4. Gestión y desactivación de cookies</Subtitle>
          <Paragraph>
            Puedes configurar tu navegador para aceptar o rechazar todas las cookies, o para recibir una notificación en pantalla cada vez que se almacene una cookie y decidir en ese momento si aceptarla o no. Ten en cuenta que si bloqueas o eliminas las cookies, es posible que ciertos servicios o funcionalidades de la Web no funcionen correctamente.
          </Paragraph>
          <Paragraph>
            A continuación te proporcionamos enlaces con información sobre cómo gestionar las cookies en los principales navegadores:
            <ul>
              <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
              <li><a href="https://support.mozilla.org/es/kb/activar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
              <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
              <li><a href="https://support.microsoft.com/es-es/help/4027947/windows-delete-cookies" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>5. Actualizaciones y cambios en la Política de Cookies</Subtitle>
          <Paragraph>
            La presente Política de Cookies puede ser actualizada en cualquier momento como resultado de cambios legislativos, reglamentarios, o como consecuencia de la implementación de nuevas funcionalidades o servicios en la Web. Te recomendamos revisar esta política periódicamente para estar informado sobre cómo y para qué utilizamos las cookies.
          </Paragraph>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default PoliticaCookies;
