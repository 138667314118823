import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Estilos para la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Font";
  font-size: 20px;
  color: #575756;
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Font";
  font-size: 18px;
  color: #050556;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-family: "Font";
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function TerminosCondiciones() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Title>TÉRMINOS Y CONDICIONES DE USO</Title>
        <StyledSeparator />

        <Section>
          <Paragraph>
            Bienvenido a www.aleronclub.es. A continuación, se detallan los términos y condiciones que regulan el acceso y uso de esta web, así como la compra de entradas y otros servicios ofrecidos. Al utilizar este sitio web, aceptas estar sujeto a los presentes términos y condiciones. Si no estás de acuerdo con alguna de las condiciones aquí expuestas, debes abstenerte de utilizar el sitio web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>1. Titularidad del sitio web</Subtitle>
          <Paragraph>
            El titular de esta página web es Selecta Creativos S.L., con CIF B44783926, y domicilio social en Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España. Si tienes alguna consulta, puedes contactar con nosotros a través del correo electrónico hola@aleronclub.es.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Objeto y ámbito de aplicación</Subtitle>
          <Paragraph>
            Estos Términos y Condiciones de Uso regulan el acceso y utilización del sitio web www.aleronclub.es, cuyo propósito es ofrecer información, servicios y la venta de entradas para eventos o actividades organizadas por Alerón Club.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Condiciones de acceso y uso del sitio web</Subtitle>
          <Paragraph>
            3.1. <strong>Acceso al sitio web:</strong> El acceso y uso de la web www.aleronclub.es es gratuito, aunque ciertos servicios, como la compra de entradas, pueden estar sujetos a una contraprestación económica.
          </Paragraph>
          <Paragraph>
            3.2. <strong>Registro de usuarios:</strong> Para acceder a ciertos servicios, como la compra de entradas, el usuario deberá registrarse en la web proporcionando información personal veraz y actualizada.
          </Paragraph>
          <Paragraph>
            3.3. <strong>Uso adecuado del sitio web:</strong> El usuario se compromete a utilizar la web de manera adecuada y conforme a la ley, la moral y las buenas costumbres, y a no realizar actividades que puedan dañar o sobrecargar el sitio web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>4. Condiciones de venta</Subtitle>
          <Paragraph>
            4.1. <strong>Proceso de compra:</strong> Para realizar una compra de entradas, el usuario debe seleccionar el evento y seguir el procedimiento de compra en línea. 
          </Paragraph>
          <Paragraph>
            4.2. <strong>Precios y pagos:</strong> Los precios de las entradas estarán expresados en euros (€). Los pagos se realizarán mediante los métodos disponibles en la web.
          </Paragraph>
          <Paragraph>
            4.3. <strong>Confirmación de compra:</strong> Tras realizar el pago, el usuario recibirá una confirmación por correo electrónico con los detalles de su compra.
          </Paragraph>
          <Paragraph>
            4.4. <strong>Envío de entradas:</strong> Las entradas se enviarán en formato electrónico al correo proporcionado por el usuario.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>5. Política de cancelaciones y devoluciones</Subtitle>
          <Paragraph>
            5.1. <strong>Cancelación de eventos:</strong> En caso de que un evento sea cancelado, Alerón Club ofrecerá opciones de reembolso o cambio de fecha.
          </Paragraph>
          <Paragraph>
            5.2. <strong>Devoluciones:</strong> Las entradas no son reembolsables, salvo en los casos establecidos por la normativa vigente.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>6. Propiedad intelectual</Subtitle>
          <Paragraph>
            6.1. <strong>Contenido del sitio web:</strong> Todos los derechos de propiedad intelectual sobre el contenido de la web pertenecen a Selecta Creativos S.L. Queda prohibida la reproducción sin autorización.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>7. Limitación de responsabilidad</Subtitle>
          <Paragraph>
            Alerón Club no se responsabiliza de los daños derivados de interferencias, virus informáticos o desconexiones en el funcionamiento de la web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>8. Enlaces a terceros</Subtitle>
          <Paragraph>
            Alerón Club no se hace responsable del contenido, productos o servicios ofrecidos en sitios web de terceros a los que se acceda mediante enlaces desde la web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>9. Modificación de los términos y condiciones</Subtitle>
          <Paragraph>
            Alerón Club se reserva el derecho a modificar estos Términos y Condiciones en cualquier momento. Las modificaciones serán efectivas desde su publicación en la web.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>10. Ley aplicable y jurisdicción</Subtitle>
          <Paragraph>
            Estos términos se rigen por la legislación española. Cualquier controversia se someterá a los tribunales de Ávila, España.
          </Paragraph>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default TerminosCondiciones;
