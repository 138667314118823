import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '../src/fonts/fonts.css';

const ImageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 1024px) {
    overflow-x: scroll; /* Solo permitir desplazamiento horizontal */
    overflow-y: hidden; /* Deshabilitar el desplazamiento vertical */
    width: 100%;
    height: 100vh;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 1080px;
  background: url('/images/HOME_V5_2K_WEB.png') no-repeat center calc(-100px); /* Subir la imagen */
  background-size: cover;
  position: relative;

  @media (max-width: 1024px) {
    width: 1920px; /* Mantén el ancho original en tabletas y móviles */
    height: 1080px; /* Mantén la altura original */
    background-position: center calc(-100px); /* Subir la imagen igual que en escritorio */
  }
`;

const Point = styled(Link)`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #050556;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  animation: pulse 3s infinite;
  cursor: pointer;
  z-index: 10;
  top: ${({ top }) => top}px; /* Usamos píxeles */
  left: ${({ left }) => left}px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    animation: wave 3s infinite;
    pointer-events: none;
    z-index: -1;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
    }
    50% {
      transform: scale(1.4);
      box-shadow: 0 0 30px rgba(255, 255, 255, 0.8);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
    }
  }

  @keyframes wave {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(4);
      opacity: 0;
    }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%) translateY(10px) scale(0.8); /* Comienza más pequeño y desplazado */
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-family: "Font";
  font-size: 14px;
  font-weight: bold;
  display: none; /* Inicialmente oculto */
  z-index: 100;
  border: 1px solid #ccc;
  opacity: 0; /* Inicia invisible */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out; /* Transición suave y lenta */

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
  }

  /* Animación cuando se muestra */
  ${Point}:hover + & {
    display: block;
    transform: translateX(-50%) translateY(0px) scale(1); /* Crece al tamaño normal y se mueve ligeramente hacia arriba */
    opacity: 1; /* Aparece completamente */
  }
`;

const points = [
  { top: 200, left: 800, link: '/tienda', text: 'SHOP' },
  { top: 500, left: 920, link: '/nosotros', text: 'NOSOTROS' },
  { top: 150, left: 1400, link: '/media', text: 'MEDIA' },
  { top: 162, left: 288, link: '/tickets', text: 'TICKETS' },
  { top: 230, left: 1150, link: '/carnets', text: 'CARNETS' },
  { top: 708, left: 1300, link: '/contacto', text: 'RRSS / CONTACTO' }
];

const ImageMap = () => {
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        const container = imageContainerRef.current;
        if (container) {
          container.scrollLeft = (container.scrollWidth - window.innerWidth) / 2; // Centrado horizontal
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ImageContainer ref={imageContainerRef}>
      <Image>
        {points.map((point, index) => (
          <React.Fragment key={index}>
            <Point to={point.link} top={point.top} left={point.left} />
            <Tooltip  className="tooltip" style={{ top: `calc(${point.top}px - 35px)`, left: `${point.left}px` }}>
              {point.text}
            </Tooltip>
          </React.Fragment>
        ))}
      </Image>
    </ImageContainer>
  );
};

export default ImageMap;
