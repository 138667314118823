import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Estilos para la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Font";
  font-size: 20px;
  color: #575756;
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Font";
  font-size: 18px;
  color: #050556;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-family: "Font";
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function PoliticaDevolucionesEnvio() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Title>POLÍTICA DE DEVOLUCIONES</Title>
        <StyledSeparator />

        <Section>
          <Subtitle>1. Cancelación de eventos y devolución de entradas</Subtitle>
          <Paragraph>
            1.1. <strong>Cancelación del evento por parte de Alerón Club:</strong> En caso de que Alerón Club cancele un evento, los usuarios tendrán derecho a un reembolso completo del importe pagado o a un cambio de fecha o evento, sujeto a disponibilidad.
          </Paragraph>
          <Paragraph>
            1.2. <strong>Modificación de eventos:</strong> Si se modifica la fecha o el programa de un evento y el usuario no puede asistir, podrá solicitar el reembolso total de las entradas.
          </Paragraph>
          <Paragraph>
            1.3. <strong>Entradas no reembolsables:</strong> Salvo los motivos mencionados anteriormente, las entradas adquiridas no serán reembolsables ni intercambiables.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Devolución de ropa y artículos de merchandising</Subtitle>
          <Paragraph>
            2.1. <strong>Devoluciones por defectos de fabricación o errores en el pedido:</strong> Si recibes un artículo defectuoso o incorrecto, podrás solicitar la devolución o cambio del producto sin coste adicional.
          </Paragraph>
          <Paragraph>
            2.2. <strong>Devoluciones voluntarias (derecho de desistimiento):</strong> Si deseas devolver un producto por cualquier otra razón, tienes un plazo de 14 días naturales desde la recepción para ejercer el derecho de desistimiento.
          </Paragraph>
          <Paragraph>
            2.3. <strong>Productos excluidos del derecho de devolución:</strong> Por razones de higiene y seguridad, no se aceptarán devoluciones de ropa interior, productos personalizados, ni artículos de merchandising abiertos y utilizados.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Procedimiento de devolución</Subtitle>
          <Paragraph>
            Para solicitar una devolución, contacta con nuestro equipo a través de hola@aleronclub.es, proporcionando los detalles de tu pedido.
          </Paragraph>
        </Section>

        <Title>POLÍTICA DE ENVÍO</Title>
        <StyledSeparator />

        <Section>
          <Subtitle>1. Envío de entradas electrónicas</Subtitle>
          <Paragraph>
            1.1. <strong>Método de envío:</strong> Las entradas adquiridas se enviarán en formato electrónico al correo proporcionado. El envío se realiza de manera automática tras la confirmación del pago.
          </Paragraph>
          <Paragraph>
            1.2. <strong>Plazo de entrega:</strong> Las entradas se enviarán inmediatamente después de la confirmación del pago. Si no las recibes en un plazo de 1 hora, revisa tu correo no deseado o contacta con nosotros.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Envío de ropa y artículos de merchandising</Subtitle>
          <Paragraph>
            2.1. <strong>Zonas de envío:</strong> Realizamos envíos a nivel nacional e internacional, con costes y plazos de entrega variables según el destino.
          </Paragraph>
          <Paragraph>
            2.2. <strong>Plazos de entrega:</strong> España (Península y Baleares): 2 a 5 días laborables. Canarias, Ceuta y Melilla: 5 a 10 días. Internacional: 7 a 15 días laborables.
          </Paragraph>
          <Paragraph>
            2.3. <strong>Costes de envío:</strong> Los gastos de envío se calcularán durante el proceso de compra y dependerán del destino y el peso del pedido.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Problemas con el envío</Subtitle>
          <Paragraph>
            3.1. <strong>Retrasos en la entrega:</strong> Si tu pedido no llega en el plazo estimado, ponte en contacto con nosotros en hola@aleronclub.es.
          </Paragraph>
          <Paragraph>
            3.2. <strong>Envíos fallidos o incorrectos:</strong> Si la entrega no puede realizarse por un error en los datos proporcionados por el cliente, intentaremos resolver el problema. Si es necesario realizar un nuevo envío, los costes adicionales correrán a cargo del cliente.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>4. Envíos internacionales y aduanas</Subtitle>
          <Paragraph>
            Los usuarios son responsables de cualquier gasto adicional relacionado con impuestos, tasas aduaneras o aranceles que puedan aplicarse en su país de destino.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>5. Cambios de dirección</Subtitle>
          <Paragraph>
            Si necesitas modificar la dirección de entrega, contáctanos lo antes posible en hola@aleronclub.es. Intentaremos realizar el cambio siempre que el pedido no haya sido enviado.
          </Paragraph>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default PoliticaDevolucionesEnvio;
