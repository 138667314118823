import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Marco1 } from "./svg/up.svg";
import { ReactComponent as Marco2 } from "./svg/down.svg";
import { ReactComponent as LogoAleron } from "./svg/logo.svg";
import { ReactComponent as LogoAleronMovil } from "./svg/logomvl.svg";
import "../fonts/fonts.css"; // Ajusta la ruta según tu estructura de archivos

const NavbarContainer = styled.nav`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-sizing: border-box;

  @media (max-width: 1366px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 80px;
    background-color: #050556;
  }
`;

const SVGContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1366px) {
    display: none; // Oculta los SVGs en tablet y móvil
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
      justify-content: space-evenly;

  @media (max-width: 1366px) {
    justify-content: space-between;
    flex-direction: row;
  }

  p {
    font-family: "Font";
    color: rgb(87, 87, 86);
    margin-right: 2rem;
    white-space: nowrap;

    @media (max-width: 1024px) {
      margin-right: 1rem;
    }

    @media (max-width: 768px) {
      margin-right: 0.5rem;
    }
  }
`;

const LogoAleronStyled = styled(LogoAleron)`
  width: 200px;
  height: auto;

  @media (max-width: 1366px) {
    width: 150px;
    margin-top: 0;
  }
`;

const MobileLogo = styled(LogoAleronMovil)`
  display: none;

  @media (max-width: 1366px) {
    display: block;
    width: 180px;
    height: auto;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: #050556ed;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.4s ease-in-out;
  z-index: 1000;
  padding-top: 0;
  margin: 0;
  font-family: "Font";

  @media (max-width: 1366px) {
    display: flex;
    justify-content: center;
  }
`;

const MenuItem = styled.li`
  margin: 15px 0;
  font-size: 1.5rem;
  font-family: "Font" !important;

  a {
    color: white;
    text-decoration: none;
    font-family: "Font";

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Hamburger = styled.div`
  display: none;

  @media (max-width: 1366px) {
    display: block;
    cursor: pointer;
    z-index: 1001;
    position: relative;
    margin-left: auto;
  }
`;

const Bar = styled.div`
  width: 30px;
  height: 3px;
  background-color: ${(props) => (props.open ? "transparent" : "white")};
  margin: 4px 0;
  transition: 0.4s;

  ${(props) =>
    props.open &&
    `
    &:nth-child(1) {
      transform: rotate(45deg) translate(12px, -2px);
      background-color: white;
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: rotate(-45deg) translate(12px, 2px);
      background-color: white;
    }
  `}
`;

const MobileLogoWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 1366px) {
    display: flex;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavbarContainer>
      <SVGContainer>
        <Marco1 />
        <LinksContainer className="content">
          <div style={{ display: "flex" }}>
            <Link to="/" style={{ textDecoration: "none", marginRight: "4rem"}}>
              <p>INICIO</p>
            </Link>
            <Link to="/tickets" style={{ textDecoration: "none", marginRight: "4rem"}}>
              <p>TICKETS</p>
            </Link>
            <Link to="/media" style={{ textDecoration: "none" , marginRight: "4rem"}}>
              <p>MEDIA</p>
            </Link>
          </div>
          <Link to="/">
  <LogoAleronStyled style={{ marginTop: "1.5rem", marginLeft: "4rem" }} />
</Link>
          <div style={{ display: "flex" }}>
            <Link to="/carnets" style={{ textDecoration: "none", marginLeft: "4rem" }}>
              <p>CARNETS</p>
            </Link>
            <Link to="/tienda" style={{ textDecoration: "none" ,marginLeft: "4rem"}}>
              <p>SHOP</p>
            </Link>
            <Link to="/contacto" style={{ textDecoration: "none", marginLeft: "4rem" }}>
              <p>CONTACTO</p>
            </Link>
          </div>
        </LinksContainer>
        <Marco2 />
      </SVGContainer>

      <MobileLogoWrapper to="/">
        <MobileLogo style={{ width: "50px", height: "auto" }} />
      </MobileLogoWrapper>

      <Hamburger onClick={toggleMenu}>
        <Bar open={menuOpen} />
        <Bar open={menuOpen} />
        <Bar open={menuOpen} />
      </Hamburger>
      <Menu open={menuOpen}>
        <MenuItem>
          <Link to="/">INICIO</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/tickets">TICKETS</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/media">MEDIA</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/carnets">CARNETS</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/tienda">SHOP</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/contacto">CONTACTO</Link>
        </MenuItem>
      </Menu>
    </NavbarContainer>
  );
};

export default Navbar;
