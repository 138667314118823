import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';

// Estilos para la página
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Font";
  font-size: 20px;
  color: #575756;
  text-align: center;
  margin-bottom: 1rem;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Font";
  font-size: 18px;
  color: #050556;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-family: "Font";
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

function PoliticaPrivacidad() {
  return (
    <PageContainer>
      <Navbar />
      <Content>
        <Title>POLÍTICA DE PRIVACIDAD</Title>
        <StyledSeparator />

        <Section>
          <Paragraph>
            En Selecta Creativos S.L. nos tomamos muy en serio la protección de los datos personales de nuestros usuarios y clientes. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas a través de nuestro sitio web www.aleronclub.es (en adelante, "la Web").
          </Paragraph>
          <Paragraph>
            Te recomendamos que leas detenidamente esta Política de Privacidad para comprender cómo tratamos tus datos personales y los derechos que tienes en relación con ellos.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>1. Responsable del tratamiento</Subtitle>
          <Paragraph>
            Titular: Selecta Creativos S.L.
            <br />
            CIF: B44783926
            <br />
            Domicilio social: Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España.
            <br />
            Correo electrónico de contacto: hola@aleronclub.es
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>2. Datos que recopilamos</Subtitle>
          <Paragraph>
            A través de la Web, podemos recopilar las siguientes categorías de datos personales:
            <ul>
              <li>Datos identificativos: Nombre, apellidos, dirección de correo electrónico, número de teléfono.</li>
              <li>Datos de transacción: Información sobre la compra de entradas, incluyendo detalles de pago y direcciones de facturación.</li>
              <li>Datos de navegación: Información derivada del uso de la Web mediante cookies o tecnologías similares (consulta nuestra Política de Cookies para más detalles).</li>
              <li>Otros datos: Cualquier información adicional que proporciones a través de formularios de contacto, encuestas u otros medios de comunicación con la Web.</li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>3. Finalidades del tratamiento</Subtitle>
          <Paragraph>
            Los datos personales que nos facilites serán tratados para las siguientes finalidades:
            <ul>
              <li>Gestión de la compra de entradas.</li>
              <li>Atención al cliente.</li>
              <li>Comunicaciones comerciales, siempre que hayas dado tu consentimiento.</li>
              <li>Cumplimiento de obligaciones legales.</li>
              <li>Mejora de nuestros servicios mediante análisis de la Web.</li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>4. Base jurídica del tratamiento</Subtitle>
          <Paragraph>
            Tratamos tus datos personales en base a las siguientes bases legales:
            <ul>
              <li>Ejecución de un contrato: Para gestionar la compra de entradas.</li>
              <li>Consentimiento: Para el envío de comunicaciones comerciales.</li>
              <li>Cumplimiento de una obligación legal.</li>
              <li>Interés legítimo: Para mejorar nuestros servicios.</li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>5. Plazo de conservación de los datos</Subtitle>
          <Paragraph>
            Conservaremos tus datos personales mientras sea necesario para las finalidades para las que fueron recabados. Posteriormente, se mantendrán bloqueados para cumplir con las obligaciones legales.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>6. Destinatarios de los datos</Subtitle>
          <Paragraph>
            Tus datos personales no serán cedidos a terceros, salvo en los siguientes casos:
            <ul>
              <li>Prestadores de servicios que nos ayuden en la ejecución de los servicios.</li>
              <li>Obligaciones legales ante las autoridades competentes.</li>
            </ul>
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>7. Derechos del usuario</Subtitle>
          <Paragraph>
            Tienes derecho a acceder, rectificar, suprimir tus datos, oponerte al tratamiento, limitar el tratamiento y solicitar la portabilidad de tus datos. También puedes retirar tu consentimiento en cualquier momento.
            <br />
            Para ejercer estos derechos, puedes contactar con nosotros mediante:
            <br />
            Correo electrónico: hola@aleronclub.es
            <br />
            Dirección postal: Calle Encarnación, 16, Planta BJ, Puerta 5, 05005 Ávila, España.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>8. Seguridad de los datos</Subtitle>
          <Paragraph>
            En Selecta Creativos S.L. tomamos medidas de seguridad adecuadas para proteger los datos personales frente a la pérdida, mal uso, acceso no autorizado, divulgación o alteración de los mismos.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>9. Cambios en la política de privacidad</Subtitle>
          <Paragraph>
            Podremos actualizar esta Política de Privacidad en cualquier momento para adaptarla a novedades legislativas. Te informaremos a través de la Web u otros medios.
            <br />
            Fecha de última actualización: 09/09/2024
          </Paragraph>
        </Section>
      </Content>
      <Footer />
    </PageContainer>
  );
}

export default PoliticaPrivacidad;
