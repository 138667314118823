import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }

  h1 {
    color: #050556;
  }

  p {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
  }
`;

const SelectTalla = styled.select`
  padding: 10px;
  margin-top: 20px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
    border-color: #050556;
  }
`;

const AddToCartButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #050556;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #001a5e;
  }
`;

function ProductoDetalle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [producto, setProducto] = useState(null);
  const [selectedTalla, setSelectedTalla] = useState('');
  const [usuario, setUsuario] = useState('usuario-ejemplo'); // Identificador de usuario

  useEffect(() => {
    const fetchProducto = async () => {
      try {
        const response = await fetch(`https://backaleron.aleronclub.com/api/productos/${id}`, {
          headers: {
            'Authorization': 'APIAleron2025'
          }
        });
        if (!response.ok) {
          throw new Error('No se pudo cargar el producto');
        }
        const data = await response.json();
        setProducto(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducto();
  }, [id]);

  const handleTallaChange = (event) => {
    setSelectedTalla(event.target.value);
  };

  const handleAddToCart = async () => {
    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/carrito/agregar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'APIAleron2025'
        },
        body: JSON.stringify({
          usuario: usuario,
          productoId: producto.id,
          cantidad: 1,
          talla: selectedTalla || null
        })
      });

      if (response.ok) {
        alert('Producto añadido al carrito');
        navigate('/carrito'); // Redirigir a la página del carrito después de agregar el producto
      } else {
        throw new Error('Error al agregar el producto al carrito');
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!producto) {
    return <p>Cargando...</p>;
  }

  const tallasDisponibles = producto.tallas ? producto.tallas.split(',') : [];

  return (
    <PageContainer>
      <Navbar />
      <Content>
        <ProductDetail>
          <img src={producto.imagen} alt={producto.nombre} />
          <h1>{producto.nombre}</h1>
          <p>{producto.precio} €</p>
          {tallasDisponibles.length > 0 && (
            <SelectTalla value={selectedTalla} onChange={handleTallaChange}>
              <option value="">Seleccione una talla</option>
              {tallasDisponibles.map((talla) => (
                <option key={talla} value={talla}>
                  {talla}
                </option>
              ))}
            </SelectTalla>
          )}
          <AddToCartButton onClick={handleAddToCart}>
            Agregar al carrito
          </AddToCartButton>
        </ProductDetail>
      </Content>
      <Footer /> 
    </PageContainer>
  );
}

export default ProductoDetalle;
