import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ImageMap from "./ImageMap";
import Contacto from "../src/Contacto/Contacto";
import Media from "../src/Media/Media";
import Nosotros from "../src/Nosotros/Nosotros";
import Tickets from "../src/Tickets/Tickets";
import Carnets from "./Carnets/Carnets";
import ProductoDetalle from "./Shop/ProductoDetalle";
import Carrito from "./Shop/Carrito";
import NotFound from "./404/NotFound";
import AvisoLegal from "./Politicas/AvisoLegal";
import PoliticaPrivacidad from "./Politicas/PoliticaPrivacidad";
import PoliticaCookies from "./Politicas/PoliticaCookies";
import TerminosCondiciones from "./Politicas/TerminosCondiciones";
import PoliticaDevolucionesEnvio from "./Politicas/PoliticaDevolucionesEnvio";
import ScrollToTop from "./Footer/ScrollToTop";

function RedirectToShop() {
  useEffect(() => {
    window.location.href = "https://shop.aleronclub.com";
  }, []);

  return null; // No necesitas renderizar nada, solo redirigir.
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<ImageMap />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/media" element={<Media />} />
        <Route path="/tienda" element={<RedirectToShop />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/carnets" element={<Carnets />} />
        <Route path="/producto/:id" element={<ProductoDetalle />} />
        <Route path="/carrito" element={<Carrito />} />
        <Route path="*" element={< NotFound />} />
        <Route path="/aviso-legal" element={< AvisoLegal />} />
        <Route path="/politica-de-privacidad" element={< PoliticaPrivacidad />} />
        <Route path="/politica-de-cookies" element={< PoliticaCookies />} />
        <Route path="/terminos-y-condiciones" element={< TerminosCondiciones />} />
        <Route path="/devoluciones" element={< PoliticaDevolucionesEnvio />} />
      </Routes>
    </Router>
  );
}

export default App;
