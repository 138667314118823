import React from 'react';
import styled from 'styled-components';
import '../fonts/fonts.css';
import { ReactComponent as CustomSVG } from './svg/Web_Faldon.svg'; 
import { ReactComponent as Crop } from '../Navbar/svg/separadormvl.svg'; 
import { Link } from 'react-router-dom';

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SVGContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -20px;

  @media (max-width: 768px) {
    width: 85%; /* Ocupa un 85% en versión móvil */
    margin: 0 auto; /* Centrar en móvil */
  }

  svg {
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  @media (max-width: 768px) {
    svg {
      width: 100%;
    }
  }
`;

const FooterContainer = styled.footer`
  width: 100%;
  background-color: #050556;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-family: "Font";
  font-size: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterTop = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
`;

const Section = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: center;

  h4 {
    margin-bottom: 10px;
    font-weight: 100;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      
      <SVGContainer style={{marginTop: "5rem"}}>
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width:"100%"}}>
          <Crop />
          <CustomSVG style={{width: "250px", marginTop: "1rem"}} />
        </div>
      </SVGContainer>

      {/* Footer en sí */}
      <FooterContainer>
        <FooterTop>
          <Section>
            <h4><Link style={{ textDecoration: "none", color: "white" }} to="/aviso-legal">AVISO LEGAL</Link></h4>
          </Section>
          <Section>
            <h4><Link style={{ textDecoration: "none", color: "white" }} to="/politica-de-privacidad">POLÍTICA DE PRIVACIDAD</Link></h4>
          </Section>
          <Section>
            <h4><Link style={{ textDecoration: "none", color: "white" }} to="/politica-de-cookies">POLÍTICA DE COOKIES</Link></h4>
          </Section>
          <Section>
            <h4><Link style={{ textDecoration: "none", color: "white" }} to="/devoluciones">POLÍTICA DE REEMBOLSO</Link></h4>
          </Section>
          <Section>
            <h4><Link style={{ textDecoration: "none", color: "white" }} to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</Link></h4>
          </Section>
          <Section style={{marginTop: "0.5rem"}}>
            <img src="/images/A-White.png" alt="aleronclub" style={{ width: "55px", height: "auto"}} />
          </Section>
        </FooterTop>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
