import React, { useState,useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styled from 'styled-components';
import { ReactComponent as LeftArrow } from './svg/izquierda.svg'; 
import { ReactComponent as RightArrow } from './svg/derecha.svg';
import Carnet1 from './images/0001.png';
import Carnet2 from './images/0002.png';
import Carnet3 from './images/0003.png';
import Carnet4 from './images/0004.png';
import Carnet5 from './images/0005.png';
import Carnet6 from './images/0006.png';
import Carnet7 from './images/0007.png';
import Carnet8 from './images/0008.png';
import Carnet9 from './images/0009.png';
import Carnet10 from './images/0010.png';
import Carnet11 from './images/0011.png';
import Carnet12 from './images/0012.png';
import Carnet13 from './images/0013.png';
import Carnet14 from './images/0014.png';
import Carnet15 from './images/0015.png';
import Carnet16 from './images/0016.png';
import Carnet17 from './images/0017.png';
import Carnet18 from './images/0018.png';
import Carnet19 from './images/0019.png';
import Carnet20 from './images/0020.png';
import Carnet21 from './images/0021.png';
import Carnet22 from './images/0022.png';
import Carnet23 from './images/0023.png';
import Carnet24 from './images/0024.png';
import Carnet25 from './images/0025.png';
import Carnet26 from './images/0026.png';
import Carnet27 from './images/0027.png';
import Carnet28 from './images/0028.png';
import Carnet29 from './images/0029.png';
import Carnet30 from './images/0030.png';
import Carnet31 from './images/0031.png';
import Carnet32 from './images/0032.png';
import Carnet33 from './images/0033.png';
import Carnet34 from './images/0034.png';
import Carnet35 from './images/0035.png';
import Carnet36 from './images/0036.png';
import Carnet37 from './images/0037.png';
import Carnet38 from './images/0038.png';
import Carnet39 from './images/0039.png';
import Carnet40 from './images/0040.png';
import Carnet41 from './images/0041.png';
import Carnet42 from './images/0042.png';
import Carnet43 from './images/0043.png';
import Carnet44 from './images/0044.png';
import Carnet45 from './images/0045.png';
import Carnet46 from './images/0046.png';
import Carnet47 from './images/0047.png';
import Carnet48 from './images/0048.png';
import Carnet49 from './images/0049.png';
import Carnet50 from './images/0050.png';
import Carnet51 from './images/0051.png';
import Carnet52 from './images/0052.png';
import Carnet53 from './images/0053.png';
import Carnet54 from './images/0054.png';
import Carnet55 from './images/0055.png';
import Carnet56 from './images/0056.png';
import Carnet57 from './images/0057.png';
import Carnet58 from './images/0058.png';
import Carnet59 from './images/0059.png';
import Carnet60 from './images/0060.png';
import Carnet61 from './images/0061.png';
import Carnet62 from './images/0062.png';
import Carnet63 from './images/0063.png';
import Carnet64 from './images/0064.png';
import Carnet65 from './images/0065.png';
import Carnet66 from './images/0066.png';
import Carnet67 from './images/0067.png';
import Carnet68 from './images/0068.png';
import Carnet69 from './images/0069.png';
import Carnet70 from './images/0070.png';
import Carnet71 from './images/0071.png';
import Carnet72 from './images/0072.png';
import Carnet73 from './images/0073.png';
import Carnet74 from './images/0074.png';
import Carnet75 from './images/0075.png';
import Carnet76 from './images/0076.png';
import Carnet77 from './images/0077.png';
import Carnet78 from './images/0078.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as Separador } from '../Navbar/svg/separadormvl.svg';


// Contenedor principal
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
`;

// Contenido principal
const Content = styled.main`
  flex: 1;
`;

// Contenedor de la galería de imágenes
const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Tamaño máximo de la galería */
  height: auto;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%; /* Se adapta en móviles */
  }
`;

const StyledSeparator = styled(Separador)`
  width: 85%;
  margin: 10px auto;
  display: flex;

  @media (min-width: 1025px) {
    display: none;
  }
`;

// Imagen principal que muestra
const Image = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  opacity: 1;
  
  // Efecto 3D más pronunciado al pasar el ratón
  &:hover {
    transform: perspective(1000px) rotateY(15deg) scale(1.05);
  }

  // Efecto de transición suave al cambiar de imagen
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
  }

  // Reducción de tamaño en móviles para que entren las flechas a los lados
  @media (max-width: 768px) {
    max-width: 60%; /* Se hace más pequeña la imagen en móviles */
  }
`;

// Flecha izquierda y derecha
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;

  ${({ direction }) => direction === 'left' && `
    left: -10px !important;  /* Ajuste para que la flecha izquierda esté visible en móviles */
  `}

  ${({ direction }) => direction === 'right' && `
    right: -8px !important;  /* Ajuste para que la flecha derecha esté visible en móviles */
  `}

  @media (min-width: 1025px) {
    ${({ direction }) => direction === 'left' && `
      left: -400px !important;  /* Mayor separación para la flecha izquierda en pantallas grandes */
    `}

    ${({ direction }) => direction === 'right' && `
      right: -400px !important;  /* Mayor separación para la flecha derecha en pantallas grandes */
    `}
  }

  @media (max-width: 768px) {
    ${({ direction }) => direction === 'left' && `
      left: 5px;  /* Ajusta la flecha izquierda en móviles */
    `}

    ${({ direction }) => direction === 'right' && `
      right: 5px;  /* Ajusta la flecha derecha en móviles */
    `}
  }

  svg {
    width: 40px;
    height: auto;
    fill: #050556; /* Color de las flechas */
    transition: all 0.3s ease;

    &:hover {
      fill: #00247D; /* Cambia de color al pasar el ratón */
    }
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #050556;
  font-weight: bold;
`;

// Datos de imágenes
const images = [
  Carnet1,
  Carnet2,
  Carnet3,
  Carnet4,
  Carnet5,
  Carnet6,
  Carnet7,
  Carnet8,
  Carnet9,
  Carnet10,
  Carnet11,
  Carnet12,
  Carnet13,
  Carnet14,
  Carnet15,
  Carnet16,
  Carnet17,
  Carnet18,
  Carnet19,
  Carnet20,
  Carnet21,
  Carnet22,
  Carnet23,
  Carnet24,
  Carnet25,
  Carnet26,
  Carnet27,
  Carnet28,
  Carnet29,
  Carnet30,
  Carnet31,
  Carnet32,
  Carnet33,
  Carnet34,
  Carnet35,
  Carnet36,
  Carnet37,
  Carnet38,
  Carnet39,
  Carnet40,
  Carnet41,
  Carnet42,
  Carnet43,
  Carnet44,
  Carnet45,
  Carnet46,
  Carnet47,
  Carnet48,
  Carnet49,
  Carnet50,
  Carnet51,
  Carnet52,
  Carnet53,
  Carnet54,
  Carnet55,
  Carnet56,
  Carnet57,
  Carnet58,
  Carnet59,
  Carnet60,
  Carnet61,
  Carnet62,
  Carnet63,
  Carnet64,
  Carnet65,
  Carnet66,
  Carnet67,
  Carnet68,
  Carnet69,
  Carnet70,
  Carnet71,
  Carnet72,
  Carnet73,
  Carnet74,
  Carnet75,
  Carnet76,
  Carnet77,
  Carnet78,
];


function Carnets() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');



  useEffect(() => {
    setPopupVisible(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setErrorMessage('Debe aceptar los términos y condiciones.');
      return;
    }

    if (!email) {
      setErrorMessage('Debe ingresar un correo electrónico válido.');
      return;
    }

    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setPopupVisible(false);
        toast.success('Suscripción exitosa', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        setErrorMessage('Hubo un problema al suscribirse. Intente de nuevo.');
      }
    } catch (error) {
      setErrorMessage('Hubo un problema al conectarse con el servidor.');
    }
  };
  const handleNext = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setFadeIn(true);
    }, 300); // Duración del fade
  };

  const handlePrev = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setFadeIn(true);
    }, 300); // Duración del fade
  };

  return (
    <PageContainer>
      <Navbar />
      <Content style={{marginTop: "1rem"}}>       
         <h1 style={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Font",
          color: "#575756",
          fontSize: "20px",
          marginBottom: "1rem" 
        }}>
          CARNETS
        </h1>
        <StyledSeparator />

        <GalleryContainer style={{marginTop: "4rem"}}>
          <Arrow direction="left" onClick={handlePrev}>
            <LeftArrow />
          </Arrow>
          <Image 
            src={images[currentIndex]} 
            alt="Carnet" 
            className={fadeIn ? 'fade-enter-active' : 'fade-exit-active'}
          />
          <Arrow direction="right" onClick={handleNext}>
            <RightArrow />
          </Arrow>
        </GalleryContainer>
      </Content>
      {/* Popup */}
      <PopupOverlay show={popupVisible}>
        <PopupContent>
          <CloseButton onClick={() => setPopupVisible(false)}>×</CloseButton>
          <h2 style={{ color: "#050556", fontFamily: "Font" }}>¿Has venido a 5 fiestas esta temporada?</h2>
          <h4 style={{ color: "#050556", fontFamily: "Font" }}>¡Solicita tu carnet!</h4>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '90%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontFamily: "Font",
              }}
            />
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              <label htmlFor="terms" style={{ fontFamily: "Font", marginLeft: '5px' }}>
                Acepto los términos y condiciones
              </label>
            </div>
            {errorMessage && <p style={{ color: 'red', fontFamily: "Font" }}>{errorMessage}</p>}
            <button
              type="submit"
              style={{
                backgroundColor: '#050556',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
                fontFamily: "Font",
              }}
            >
              ¡Solicitar mi carnet!
            </button>
          </form>
        </PopupContent>
      </PopupOverlay>
      <ToastContainer />
      <Footer />
    </PageContainer>
  );
}

export default Carnets;
